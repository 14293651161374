<template>
  <div id="modal" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content is-relative">
      <section class="section">
        <div
          @click="closemodal"
          class="modal-close is-large"
          aria-label="close"
        ></div>
        <div class="container mb-4">
          <Arrow/>
          <strong class="is-relative ml-4"
          v-html="returnHtml(txtString.modal.headline) || ''">
            <!-- Headline -->
          </strong>
        </div>
        <div class="container">
          <p v-html="returnHtml(txtString.modal.textTop) || ''">
            <!-- Text Einzahlung - Ergebnis -->
          </p>
        </div>
        <div class="container">
          <Colorbar />
        </div>
        <div class="container">
          <div class="columns is-4">
            <div class="column">
              <div class="wrap">
                <div v-if="(getState.aktives_ziel==='rente' || getState.aktives_ziel==='auszeit' || getState.aktives_ziel==='auszeit') && getState.theme.options.urlaub" class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square green"/>
                      <div v-html="returnHtml(txtString.modal.left.vacation) || ''">
                          <!-- Urlaubstage -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.summe_urlaubstage) }} €
                  </div>
                </div>
                <div v-if="(getState.aktives_ziel==='rente' || getState.aktives_ziel==='auszeit' || getState.aktives_ziel==='auszeit') && getState.theme.options.ueberstunden"   class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square brown"/>
                      <div v-html="returnHtml(txtString.modal.left.overtime) || ''">
                          <!-- Überstunden -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.summe_ueberstunden) }} €
                  </div>
                </div>
                <div v-if="getState.theme.options.sparen_monat" class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square yellow"/>
                      <div v-html="returnHtml(txtString.modal.left.saveMonth) || ''">
                        <!-- Monatsgehalt -->
                    </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas((getState.summe_nettoEinzahlungMonat + getState.summe_nettoEinzahlungMonatLetztesJahr)) }} €
                  </div>
                </div>
                <div v-if="getState.theme.options.sparen_jahr" class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square orange"/>
                      <div v-html="returnHtml(txtString.modal.left.saveYear) || ''">
                        <!-- Sonderzahlungen -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.summe_nettoSonderzahlungJahr) }} €
                  </div>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square red"/>
                      <div v-html="returnHtml(txtString.modal.left.return) || ''">
                        <!-- Rendite -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.rendite_gewinn) }} €
                  </div>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square purple"/>
                      <div v-html="returnHtml(txtString.modal.left.taxes) || ''">
                          <!-- Steuer & Sozialversicherung -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.steuerersparnis) }} €
                  </div>
                </div>
                <div class="level is-mobile" v-if="getState.theme.options.arbeitgeberzuschuss">
                  <div class="level-left">
                    <div class="colored_square blue"/>
                      <div v-html="returnHtml(txtString.modal.left.employerSub) || ''">
                          <!-- Arbeitgeberzuschuss -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.summe_arbeitgeber) }} €
                  </div>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="colored_square lightblue"/>
                      <div v-html="returnHtml(txtString.modal.left.credit) || ''">
                          <!-- Vorhandenes Guthaben -->
                      </div>
                  </div>
                  <div class="level-right">
                    {{ addCommas(getState.vorhandenes_guthaben) }} €
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-hidden-touch">
              <div class="wrap">
                <p v-html="returnHtml(txtString.modal.right.text) || ''">
                  <!-- Details Text summary -->
                </p>
                <p>
                  <!-- Damit stehen Ihnen monatlich {{ addCommas(getState.zusatzrente) }} €**
                  zusätzlich im Rentenalter bereit. -->
                </p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="columns">
              <div class="column column is-6-tablet">
                <div class="mt-4">
                  <div class="zusatz">
                    <p v-html="returnHtml(txtString.modal.left.note) || ''">
                     <!-- note -->
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-hidden">
                <div class="wrap">
                  <div class="zusatz">
                    <p>
                      <!-- note - Geld im Alter -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="container has-text-centered" v-if="getState.theme.options.cta_contact">
              <div class="contact">
                <a  href="#contact-form" @click="closemodal"
                  ><span>JETZT KONTAKTIEREN</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Colorbar from "./Colorbar.vue"
import Arrow from "../svg/Arrow.vue"
export default {
    name: "Details",
    components: {
        Colorbar,
        Arrow
    },
    data: () => {
        return {
            anrede: null
        }
    },
    methods: {
        closemodal: function () {
            document.getElementById("modal").classList.remove("is-active")
            //   document.querySelector("html").style.overflow="visible";
        },
        addCommas: function (nStr) {
            nStr += ""
            const x = nStr.split(".")
            let x1 = x[0]
            const x2 = x.length > 1 ? "." + x[1] : ""
            const rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, "$1" + "." + "$2")
            }
            return x1 + x2
        }
    },
    computed: {
        getState () {
            return this.$store.getters.getState
        }
    },
    created () {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .modal-close{
//     position: absolute;
//     top:0;
//     right:0;
// }
</style>
